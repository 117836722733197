import { inject, Injectable } from '@angular/core';
import { I18nService } from '@app/@i18n/i18n.service';

@Injectable({
  providedIn: 'root',
})
export class ChangeLangueService {
  private i18nService = inject(I18nService);

  changeLange = false;

  set Langue(langChanged: boolean) {
    this.changeLange = langChanged;
  }

  get Langue() {
    return this.changeLange;
  }

  getLangByKey(key: string): any {
    const supportedLangs = this.i18nService.getSupportedLangs();
    let defaultLang = this.i18nService.getSupportedLangs().find((lang) => lang.langId === 'fr')

    for (const lang of supportedLangs) {

      if (lang.langId === key.toLowerCase()) {
        defaultLang = lang;
      }
    }

    return defaultLang;
  }
}
