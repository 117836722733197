<app-spinner></app-spinner>
<div class="d-flex flex-column widthComponent">
  <div class="d-flex flex-column align-items-stretch heightComponent">
    <router-outlet (activate)="outletActivate()"></router-outlet>
    @if (pageLoaded) {
      <footer class="d-xl-inline d-lg-inline d-md-inline d-sm-inline bg-light footerPosition">
        <app-footer></app-footer>
      </footer>
    }
  </div>
</div>
